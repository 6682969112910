<template>
  <v-card flat>
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      icon="mdi-alpha-i-box-outline"
      empty-text="No invoice yet"
      :loading="loading_items"
      title="Invoices"
      style="min-height: 500px"
      :no-more-data="!has_more_items"
      :show-select="false"
      @load-more="loadMoreItems"
    >
      <template v-slot:row-slot="{ item }">
        <td>
          {{ item.due_date | format("LL") }}
        </td>
        <td>{{ item.id_normalize }}</td>
        <td class="cursor-pointer" @click="open_view_dialog(item)">
          {{ item.title | truncate(20) }}
        </td>
        <td>
          <Avatar :user="item.billable_from"></Avatar>
        </td>
        <td>
          <Avatar :user="item.billable_to"></Avatar>
        </td>
        <td>{{ item.total | money }}</td>
        <td>{{ item.status | ucwords }}</td>
        <Actions
          :item="item"
          :permissions="$_permissions.get('invoices')"
          :has-delete="false"
          :has-edit="false"
          @view="action_view_dialog(true, item)"
        >
          <template v-slot:extra>
            <v-list-item v-if="can_pay(item)" @click="navigatePayment(item)">
              <v-list-item-title>
                <v-icon left>mdi-cash</v-icon>
                Pay
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="
                can_remind(item) && item.status !== 'paid' && !item.is_recurring
              "
              @click="remind_invoice(item)"
            >
              <v-list-item-title>
                <v-icon left>mdi-bell-circle-outline</v-icon>
                Remind
              </v-list-item-title>
            </v-list-item>
          </template>
        </Actions>
      </template>
    </VueTable>
    <ViewInvoice v-model="view_dialog" :invoice="activeItem"></ViewInvoice>
  </v-card>
</template>

<script>
import { fetch_services } from "@/services/fetch/fetch_services";
import { mapGetters } from "vuex";
import request from "@/services/axios_instance";
import Actions from "@/common/VueTable/ActionDropdown.vue";
import VueTable from "@/common/VueTable/VueTable.vue";
import ViewInvoice from "@/modules/Invoice/components/ViewInvoice/ViewInvoice.vue";
export default {
  mixins: [fetch_services],
  components: {
    Actions,
    VueTable,
    ViewInvoice,
  },
  data: () => ({
    headers: [
      { text: "Due Date", sortable: true, value: "due_date" },
      { text: "Invoice #", sortable: true, value: "id_normalize" },
      { text: "Title", sortable: true, value: "title" },
      { text: "Billed From", sortable: false },
      { text: "Billed To", sortable: false },
      { text: "Amount", sortable: true, value: "total" },
      { text: "Status", sortable: true, value: "status" },
      {
        text: "Action",
        sortable: false,
        value: "action",
        width: "40px",
        align: "center",
      },
    ],
  }),
  created() {
    this.loadItems(`api/invoice/user/${this.view_user.id}`, true, null, null);
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("memberProfile", ["view_user"]),
  },
  methods: {
    can_pay(invoice) {
      return this.user.id === invoice.billable_to.id;
    },
    can_remind(invoice) {
      return this.user.id === invoice.billable_from.id;
    },
    remind_invoice(invoice) {
      let payload = {
        invoice_ids: [invoice.id],
      };
      this.$store.commit("set_loader", true);
      request
        .post(`api/invoice/bulk-remind`, payload)
        .then(({ data }) => {
          this.appSnackbar( "Invoice reminder sent!");
        })
        .catch((err) => {
          this.$store.commit("set_loader", false);
          throw err;
        })
        .finally(() => {
          this.$store.commit("set_loader", false);
        });
    },
    navigatePayment(invoice) {
      this.$router.push({ name: "pay-invoice", params: { id: invoice.id } });
    },
  },
};
</script>

<style>
</style>